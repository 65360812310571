import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'AboutUs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "AboutUs" */ '../views/AboutView.vue')
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: () => import(/* webpackChunkName: "Apply" */ '../views/ApplyView.vue')
  },
  {
    path: '/warranty-registration',
    name: 'WarrantyRegistration',
    component: () => import(/* webpackChunkName: "WarrantyRegistration" */ '../views/WarrantyRegistrationView.vue')
  },
  {
    path: '/terms-and-conditions',
    name: 'TermsAndConditions',
    component: () => import(/* webpackChunkName: "TermsAndConditions" */ '../views/TermsAndConditionsView.vue')
  },
  {
    path: '/products/:category?',
    name: 'ProductRange',
    component: () => import(/* webpackChunkName: "ProductRange" */ '../views/ProductRangeView.vue'),
    props: true
  },
  {
    path: '/product/:sku?',
    name: 'Product',
    component: () => import(/* webpackChunkName: "Product" */ '../views/ProductView.vue'),
    props: true
  },
  // {
  //   path: '/resources/:page?',
  //   name: 'Resources',
  //   component: () => import(/* webpackChunkName: "Resources" */ '../views/ResourcesView.vue'),
  //   props: true
  // },
  // {
  //   path: '/resources/:slug',
  //   name: 'Article',
  //   component: () => import(/* webpackChunkName: "ResourcesArticle" */ '../views/ResourcesArticleView.vue'),
  //   props: true
  // },
  {
    path: '/solutions',
    name: 'Solutions',
    component: () => import(/* webpackChunkName: "Solutions" */ '../views/SolutionsView.vue')
  },
  {
    path: '/solutions/:slug',
    name: 'Solution',
    component: () => import(/* webpackChunkName: "Solution" */ '../views/SolutionInfoView.vue'),
    props: true
  },
  // {
  //   path: '/privacy-policy',
  //   name: 'PrivacyPolicy',
  //   component: () => import(/* webpackChunkName: "PrivacyPolicy" */ '../views/PrivacyPolicyView.vue')
  // },
  // {
  //   path: '/terms-and-conditions',
  //   name: 'TermsAndConditions',
  //   component: () => import(/* webpackChunkName: "TermsAndConditions" */ '../views/TermsAndConditionsView.vue')
  // },
  {
    path: '/404',
    name: 'PageNotFound',
    component: () => import(/* webpackChunkName: "PageNotFound" */ '../views/PageNotFoundView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: "/404"
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // Exists when Browser's back/forward pressed
    if (savedPosition) {
      return savedPosition
    // For anchors
    } else if (to.hash) {
      return { el: to.hash }
    // By changing queries we are still in the same component, so "from.path" === "to.path" (new query changes just "to.fullPath", but not "to.path").
    } else if (from.path === to.path) {
      return {}
    } 
    else if ((from.name === 'ProductRange') && (to.name === 'ProductRange')) {
      return { el: '#product-range' , top: 90 }
    }

    if (from && Object.keys(to.query).length) {
      if (to.fullPath.split('?')[0] == from.fullPath.split('?')[0]) return;
    }

    return { top: 0, behavior: 'smooth' }; 
  }
})

router.beforeEach(() => {
  document.getElementById("Nav")?.classList?.remove("show");
});

router.afterEach(() => {
  if(router.currentRoute.value.name && !['ContactUs', 'WarrantyRegistration', 'TermsAndConditions'].includes(router.currentRoute.value.name as string)) {
    const reCapthca = document.querySelector('.grecaptcha-badge') as any;
    if(reCapthca) {
      reCapthca.style.visibility = 'hidden';
    }
  } else {
    const reCapthca = document.querySelector('.grecaptcha-badge') as any;
    if(reCapthca) {
      reCapthca.style.visibility = `visible`;
    }
  }
});

export default router
