import { createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3dffd4f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "generic-banner" }
const _hoisted_2 = { class: "generic-banner-inner" }
const _hoisted_3 = { class: "generic-banner-item position-relative" }
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          src: `${require('@/assets/' + _ctx.image.src.replace(/^\//g, ''))}`,
          class: "d-block",
          alt: _ctx.image.alt || ''
        }, null, 8, _hoisted_4),
        _createElementVNode("div", {
          class: _normalizeClass(["position-absolute", `generic-banner-heading ${_ctx.image.headingClass || 'top-right'}`])
        }, [
          (_ctx.image.heading)
            ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.isH1 ? 'h1' : 'h2'), {
                key: 0,
                class: _normalizeClass(["h1 text-uppercase fw-bold", {'mb-2 mb-lg-3': _ctx.image.copy, 'mb-0': !_ctx.image.copy}]),
                innerHTML: _ctx.image.heading
              }, null, 8, ["class", "innerHTML"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            innerHTML: _ctx.image.copy
          }, null, 8, _hoisted_5),
          _renderSlot(_ctx.$slots, "default", {}, undefined, true),
          (_ctx.image.buttonText && _ctx.image.linkTo)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 1,
                to: _ctx.image.linkTo || '/',
                class: "text-decoration-none"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("button", {
                    class: _normalizeClass(["btn px-3 px-lg-4 py-1 py-lg-2", _ctx.image.buttonClass || 'btn-primary'])
                  }, _toDisplayString(_ctx.image.buttonText), 3)
                ]),
                _: 1
              }, 8, ["to"]))
            : _createCommentVNode("", true)
        ], 2)
      ])
    ])
  ]))
}